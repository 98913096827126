import React, { useState, useEffect } from "react";
import { Login } from "./Login";
import * as XxlEvent from "../../../utils/xxl-event";
import type { OpenLoginEventData } from "../../../utils/xxl-event";
import { ViewState } from "../../../utils/xxl-event";

type LoginModalWrapperProps = {
  onClose?: () => void;
  initialVewState?: ViewState;
  isOpen?: boolean;
};
export const LoginModalWrapper = ({
  onClose,
  initialVewState = ViewState.LOGIN,
  isOpen = false,
}: LoginModalWrapperProps) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [viewState, setViewState] = useState<ViewState>(initialVewState);
  const toggleLogin = (show: boolean) => {
    setIsVisible(show);
    if (show === false && typeof onClose === "function") {
      onClose();
    }
  };

  useEffect(() => {
    const fn = (event: CustomEvent<OpenLoginEventData | undefined>) => {
      toggleLogin(true);
      setViewState(event.detail?.viewState ?? initialVewState);
    };

    XxlEvent.addXXLEventListener(XxlEvent.XXL_LOGIN_CLICK, fn);

    return () => XxlEvent.removeXXLEventListener(XxlEvent.XXL_LOGIN_CLICK, fn);
  }, []);

  return isVisible ? (
    <Login setShowLogin={toggleLogin} initialViewState={viewState} />
  ) : null;
};
