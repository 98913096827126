import type { SiteIdentifier } from "@xxl/common-utils";
import type { ToggleCookie } from "react-app/src/components/XXLDynamicToggle/types";
import {
  getEnvVar,
  getSsmApiConfiguration,
  isNotProd,
  isTrue,
} from "../utils/environment-variables";
import { Configuration, SsmApi } from "@xxl/ssm-api";
import { type BooleanToggles, type StringToggles } from "@/react-app/global";

const ssmApi = new SsmApi(new Configuration(getSsmApiConfiguration()));

interface GetSsmParametersInput {
  siteId: SiteIdentifier;
  parameters: {
    parameterName: string;
    fallback: string;
  }[];
}

interface GetDynamicTogglesKeyValuePairsInput {
  siteUid: SiteIdentifier;
  namesOfBooleanToggles: (keyof BooleanToggles)[];
  namesOfStringToggles: (keyof StringToggles)[];
  dynamicToggleValues: ToggleCookie | null;
}

const getSsmParameters = async ({
  siteId,
  parameters,
}: GetSsmParametersInput): Promise<
  Record<(typeof parameters)[number]["parameterName"], string>
> => {
  let names;
  try {
    names = parameters
      .map((parameter) => parameter.parameterName)
      .filter((parameter) => parameter)
      .sort()
      .join(",");
    const { data } = await ssmApi.getParameters(names, siteId);
    const parametersFromSsmApiResponse = data.data;
    const returnValueAsEntries: [string, string][] = [];
    for (const parameter of parameters) {
      const foundParameterFromSsmApiRespone = parametersFromSsmApiResponse.find(
        (parameterFromSsmApiResponse) =>
          parameterFromSsmApiResponse.queryName === parameter.parameterName
      );
      if (
        foundParameterFromSsmApiRespone !== undefined &&
        foundParameterFromSsmApiRespone.value !== null
      ) {
        returnValueAsEntries.push([
          parameter.parameterName,
          foundParameterFromSsmApiRespone.value,
        ]);
      } else {
        returnValueAsEntries.push([
          parameter.parameterName,
          parameter.fallback,
        ]);
      }
    }
    return Object.fromEntries(returnValueAsEntries);
  } catch (error) {
    if (isNotProd()) {
      throw Error(`Failed to get ${names ?? ""} from ssm-api`);
    }
    throw error as Error;
  }
};

const mapSsmParametersToBooleanToggles = (
  parameters: Record<string, string>
): BooleanToggles =>
  Object.fromEntries(
    Object.entries(parameters).map(([key, value]) => [
      key,
      value.toLowerCase().trim() === true.toString(),
    ])
  ) as BooleanToggles;

const getDynamicTogglesKeyValuePairs = async ({
  namesOfBooleanToggles,
  namesOfStringToggles,
  dynamicToggleValues,
  siteUid,
}: GetDynamicTogglesKeyValuePairsInput): Promise<
  | Required<Record<(typeof namesOfBooleanToggles)[number], boolean>>
  | Required<Record<(typeof namesOfStringToggles)[number], string>>
> => {
  const namesOfBooleanTogglesToBeRetrievedFromSsmApi: (keyof BooleanToggles)[] =
    [];
  const methodReturnAsEntries: (
    | [keyof BooleanToggles, boolean]
    | [keyof StringToggles, string]
  )[] = [];
  for (const name of namesOfBooleanToggles) {
    if (
      isNotProd() &&
      dynamicToggleValues !== null &&
      name in dynamicToggleValues
    ) {
      methodReturnAsEntries.push([
        name,
        dynamicToggleValues[name].includes("true"),
      ]);
    } else {
      namesOfBooleanTogglesToBeRetrievedFromSsmApi.push(name);
    }
  }
  if (
    namesOfBooleanTogglesToBeRetrievedFromSsmApi.length +
      namesOfStringToggles.length >
    0
  ) {
    const ssmParameters = await getSsmParameters({
      siteId: siteUid,
      parameters: [
        ...namesOfBooleanTogglesToBeRetrievedFromSsmApi.map((parameter) => ({
          parameterName: parameter,
          fallback: false.toString(),
        })),
        ...namesOfStringToggles.map((parameter) => ({
          parameterName: parameter,
          fallback: "",
        })),
      ],
    });
    const booleanToggles: BooleanToggles = mapSsmParametersToBooleanToggles(
      Object.fromEntries(
        Object.entries(ssmParameters).filter(([key]) =>
          namesOfBooleanTogglesToBeRetrievedFromSsmApi.includes(
            key as keyof BooleanToggles
          )
        )
      )
    );
    const stringToggles: StringToggles = Object.fromEntries(
      Object.entries(ssmParameters).filter(([key]) =>
        namesOfStringToggles.includes(key as keyof StringToggles)
      )
    ) as StringToggles;
    methodReturnAsEntries.push(
      ...(Object.entries(booleanToggles) as [keyof BooleanToggles, boolean][])
    );
    methodReturnAsEntries.push(
      ...(Object.entries(stringToggles) as [keyof StringToggles, string][])
    );
  }
  return Object.fromEntries(methodReturnAsEntries) as Record<
    (typeof namesOfBooleanToggles)[number],
    boolean
  > &
    Record<(typeof namesOfStringToggles)[number], string>;
};

const getSsmConfigParameters = async (siteId: SiteIdentifier) => {
  const getSsmParamtersResponse = await getSsmParameters({
    siteId,
    parameters: [
      {
        parameterName: "config.site.cookieVersion",
        fallback: getEnvVar("CONFIG_SITE_COOKIEVERSION"),
      },
      { parameterName: "config.site.giosgEnabled", fallback: "false" },
      { parameterName: "config.site.giosgId", fallback: "" },
      { parameterName: "config.site.logRocketApiId", fallback: "" },
      { parameterName: "config.site.serverGtmScriptUrl", fallback: "" },
    ],
  });

  return {
    cookieVersion: getSsmParamtersResponse["config.site.cookieVersion"],
    giosg: {
      giosgEnabled: isTrue(getSsmParamtersResponse["config.site.giosgEnabled"]),
      giosgId: getSsmParamtersResponse["config.site.giosgId"],
    },
    logRocketApiId: getSsmParamtersResponse["config.site.logRocketApiId"],
    serverGtmScriptUrl:
      getSsmParamtersResponse["config.site.serverGtmScriptUrl"],
  };
};

export {
  getDynamicTogglesKeyValuePairs,
  getSsmConfigParameters,
  // eslint-disable-next-line import/no-unused-modules
  getSsmParameters,
};
