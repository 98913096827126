import { QuickShop } from "@/components/QuickShop/QuickShop";
import {
  cookieCategories,
  getCustomerKey,
  getMemberNumber,
} from "@/react-utils/Cookie";
import { ThemeProvider } from "@mui/material/styles";
import { hasNoValue } from "@xxl/common-utils";
import { StatusCodes } from "http-status-codes";
import isEmpty from "lodash/isEmpty";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import type {
  AppContext,
  AppInitialProps,
  AppProps as NextAppProps,
} from "next/app";
import App from "next/app";
import { useEffect, useState } from "react";
import { CartContextProvider } from "react-app/src/components/Cart/CartContextProvider";
import { ApiClientsProvider } from "react-app/src/components/ContextProviders/ApiClientsProvider";
import { TrackingContextProvider } from "react-app/src/components/ContextProviders/TrackingContextProvider";
import { TranslationsProvider } from "react-app/src/components/ContextProviders/TranslationsProvider";
import { SnackBarWrapper } from "react-app/src/components/SnackBar";
import type { HeaderConfig } from "react-app/src/global";
import { logError } from "react-app/src/utils/xxl-log";
import { Layout } from "../components/Layout/Layout";
import { EnvironmentDataProvider } from "../components/Providers/EnvironmentDataProvider";
import { SessionProvider } from "../components/Providers/SessionProvider";
import { Symplify } from "../components/Symplify/Symplify";
import type { XXLAppData } from "../global";
import "../styles/global.scss";
import type { DeviceType } from "../utils/app-page-helper";
import {
  getDeviceTypeFromHeaders,
  getMuiTheme,
} from "../utils/app-page-helper";
import { getPageType } from "../utils/common-page-data/common-page-data";
import {
  isTeamsales as checkIsTeamsales,
  getEnvVar,
} from "../utils/environment-variables";
import { initNextJsGiosg } from "../utils/giosg-init";
import { initGtm } from "../utils/gtm";
import { invokeAfterConsent } from "../utils/invoke-after-consent";
import { initKindlyChatbot } from "../utils/kindly-chatbot";
import {
  getLayoutProps,
  type LayoutProps,
} from "../utils/layout/with-layout-page-props";
import {
  getCachedLayoutProps,
  setCachedLayoutProps,
} from "../utils/server-side-cache/server-side-cache";
import HeadMetaTags from "./HeadMetaTags";

const { NOT_FOUND } = StatusCodes;

const PAGE_TYPES_WITH_REWARDS_BANNER = [
  "checkout",
  "order-confirmation",
  "storeFinder",
  "guides",
  "campaignHub",
  "error",
];

const PAGE_TYPES_WITH_LIMITED_HEADER = ["checkout"];

const PAGE_TYPES_WITH_HIDDEN_FOOTER_TOP_MARGIN = ["checkout", "brandIndex"];

if (process.env.NODE_ENV === "development") {
  console.error(
    "React is running in strict mode, please read more about different development behaviour here: https://react.dev/reference/react/StrictMode"
  );
}

const LOGOUT_PATHNAME = "logout";

export type InitialProps = {
  campaignHubUrl: string;
  deviceType: DeviceType;
  isTeamsales: boolean;
  layoutProps: LayoutProps;
};

type AppProps<P> = {
  pageProps?: P; // can be undefined when a page fails
} & InitialProps &
  Omit<NextAppProps<P>, "pageProps">;

const XXLApp = ({
  Component,
  deviceType,
  pageProps,
  router,
  layoutProps,
  isTeamsales,
  campaignHubUrl,
}: AppProps<XXLAppData>) => {
  const isLogoutPage = router.pathname.includes(LOGOUT_PATHNAME);
  const {
    environmentData,
    giosg,
    logRocketApiId = "",
    serverGtmScriptUrl = "",
    statusCode,
    translations,
  } = pageProps ?? {};
  const theme = getMuiTheme(deviceType, environmentData?.siteUid ?? "xxl");
  const { megaMenuContent, headerContent, infoMessage, footerContent } =
    layoutProps;
  const pageType = getPageType(router.pathname);
  const [showQuickShop, setShowQuickshop] = useState<boolean>(false);

  /**
   * Make sure we user correct market and environment in local development
   */
  useEffect(() => {
    const { origin } = window.location;
    if (isEmpty(environmentData)) {
      return;
    }
    const { frontEndServerUrl } = environmentData;
    if (origin.includes("localhost") && frontEndServerUrl !== origin) {
      throw Error(
        "Please check url, seems that market and/or environment differ from parameters that server was started with."
      );
    }
  }, [environmentData]);

  useEffect(() => {
    if (isEmpty(environmentData)) {
      return;
    }
    const {
      featureToggles: { toggle_kindly_chatbot, toggle_quick_shop },
      gtmId,
      kindlyChatbotKey,
      requestMapping: { customerService, faq },
      gitHash,
    } = environmentData;

    setShowQuickshop(toggle_quick_shop);

    //GTM
    initGtm(gtmId, serverGtmScriptUrl);

    // init LogRocket
    const isPreProdEnv = environmentData.frontEndServerUrl.includes("pre.");
    const enableLogRocket =
      logRocketApiId.trim() !== "" &&
      environmentData.featureToggles.toggle_log_rocket &&
      !isPreProdEnv &&
      hasNoValue(window.Cypress);

    if (enableLogRocket) {
      invokeAfterConsent({
        callback: () => {
          LogRocket.init(logRocketApiId, {
            release: gitHash,
          });
          LogRocket.identify(getMemberNumber() ?? getCustomerKey() ?? "");
          setupLogRocketReact(LogRocket);
        },
        cookieConsentList: [cookieCategories.MARKETING],
      });
    }

    // CHAT INIT - Temporary mapping for soft launch.
    const isWhitelisted =
      document.location.pathname === "/" ||
      [`${customerService}`, `${faq}`].some((p) => router.pathname.includes(p));

    if (toggle_kindly_chatbot && isWhitelisted && !isTeamsales) {
      initKindlyChatbot(kindlyChatbotKey);
    } else if (giosg !== undefined) {
      const { giosgEnabled, giosgId } = giosg;
      if (giosgEnabled === true) {
        initNextJsGiosg(giosgId);
      }
    }
  }, [environmentData, giosg, isTeamsales, router.pathname]);

  if (isLogoutPage || statusCode === NOT_FOUND) {
    return (
      <>
        <HeadMetaTags />
        <Component {...pageProps} />
      </>
    );
  }
  const { headerLogo, headerLinks, campaignHubLink } = headerContent;
  const headerConfig: HeaderConfig = {
    logoUrl: headerLogo?.url ?? "",
    logoAlt: headerLogo?.alt ?? "",
    headerLinks: headerLinks ?? [],
    isTeamsales,
    campaignHubUrl,
    campaignHubLink: campaignHubLink,
    showHeaderLinks: !isTeamsales,
  };

  const shouldShowRewardsBanner =
    PAGE_TYPES_WITH_REWARDS_BANNER.includes(pageType);
  const shouldShowLimitedHeader =
    PAGE_TYPES_WITH_LIMITED_HEADER.includes(pageType);
  const shouldHideFooterTopMargin =
    PAGE_TYPES_WITH_HIDDEN_FOOTER_TOP_MARGIN.includes(pageType);

  return (
    <>
      <ThemeProvider theme={theme}>
        <EnvironmentDataProvider environmentData={environmentData}>
          <SessionProvider isReactApp={false}>
            <ApiClientsProvider>
              <TrackingContextProvider>
                <TranslationsProvider nextJsTranslations={translations}>
                  <CartContextProvider isTeamsales={isTeamsales}>
                    <HeadMetaTags />
                    <SnackBarWrapper />
                    {showQuickShop && <QuickShop />}
                    <Layout
                      footerContent={footerContent}
                      megaMenuContent={megaMenuContent}
                      headerConfig={headerConfig}
                      infoMessage={infoMessage}
                      shouldShowLimitedHeader={shouldShowLimitedHeader}
                      shouldShowRewardsBanner={shouldShowRewardsBanner}
                      shouldHideFooterTopMargin={shouldHideFooterTopMargin}
                    >
                      <Component {...pageProps} />
                    </Layout>
                  </CartContextProvider>
                </TranslationsProvider>
              </TrackingContextProvider>
            </ApiClientsProvider>
          </SessionProvider>
        </EnvironmentDataProvider>
      </ThemeProvider>
      {environmentData !== undefined && "symplifyId" in environmentData && (
        <Symplify id={environmentData.symplifyId} />
      )}
    </>
  );
};

XXLApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & InitialProps> => {
  try {
    const appContext = await App.getInitialProps(context);
    const { req } = context.ctx;
    const deviceType = getDeviceTypeFromHeaders(req?.headers);
    let layoutProps: LayoutProps | null = null;

    try {
      layoutProps = await getCachedLayoutProps();
    } catch (error) {
      console.error("Error while getting cached layout props", error);
    }

    if (layoutProps === null) {
      layoutProps = await getLayoutProps();
      void setCachedLayoutProps(layoutProps);
    }

    return {
      ...appContext,
      deviceType,
      layoutProps,
      isTeamsales: checkIsTeamsales(),
      campaignHubUrl: getEnvVar("REQUEST_MAPPING_CAMPAIGNHUBPAGE"),
    };
  } catch (error) {
    logError(error);
    throw Error(
      "Could not create layout props. Please see NextJS console for more information. Possible causes could be expired token or Java code for Spring needs to be compiled (if server was started with --skip-build flag)."
    );
  }
};

export default XXLApp;
