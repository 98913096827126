import { Configuration as PimApiConfiguration, PimApi } from "@xxl/pim-api";
import { getDiscountedProductCountInCategory } from "@/utils/apis/solr-api";
import {
  getLegacySiteUid,
  getPimApiConfiguration,
  getSiteUid,
} from "@/utils/environment-variables";
import { translateMessage } from "@/utils/translations/translateMessage";
import type { CategoryData } from "@xxl/frontend-api";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { CACHE_TTL_15_MIN, STALE_TTL_3_MIN } from "@/utils/apis/constants";

const discountSubCategorySlugTranslationKey =
  "category.page.discounts.subcategory.slug";
const discountSubCategorySuffix = "promo";

const pimApi = new PimApi(new PimApiConfiguration(getPimApiConfiguration()));

type DiscountCategoryData = {
  code: string;
  name: string;
  url: string | undefined;
  categoryLevel: number | undefined;
  productCount: number | undefined;
  breadcrumbs: CategoryData["breadcrumbs"];
  subCategories: CategoryData["subCategories"];
};

export const getDiscountCategoryForCategoryData = async (
  categoryData: CategoryData
): Promise<DiscountCategoryData | null> => {
  if (categoryData.code === undefined) {
    return null;
  }
  const productCount = await getDiscountedProductCountInCategory(
    categoryData.code,
    getSiteUid()
  );
  if (productCount === 0) {
    return null;
  }
  const slugName = await translateMessage(
    discountSubCategorySlugTranslationKey
  );
  const name = slugName.charAt(0).toUpperCase() + slugName.slice(1);
  const url = categoryData.url?.replace("/c/", `/${slugName}/c/`);
  const code = categoryData.code + discountSubCategorySuffix;
  const categoryLevel = (categoryData.categoryLevel ?? 0) + 1;
  return {
    code,
    name,
    url,
    categoryLevel,
    productCount,
    breadcrumbs: [
      ...(categoryData.breadcrumbs ?? []),
      {
        code,
        name,
        url,
        categoryLevel,
      },
    ],
    subCategories: [],
  };
};

const fetchCategoryData = async (
  categoryCode: string | undefined
): Promise<CategoryData | undefined> => {
  if (categoryCode === undefined) {
    return;
  }
  const isDiscountCategory = categoryCode.includes(discountSubCategorySuffix);
  const { data } = await pimApi.getCategories(
    getLegacySiteUid(),
    categoryCode.replace(discountSubCategorySuffix, "")
  );
  if (data.length === 0) {
    return;
  }
  const [categoryData] = data;
  const discountCategory =
    await getDiscountCategoryForCategoryData(categoryData);
  if (discountCategory !== null) {
    categoryData.subCategories?.push(discountCategory);
  }

  const subCategories = categoryData.subCategories
    ?.filter(({ productCount }) => (productCount ?? 0) > 0)
    .map(({ code, name, url }) => ({ code, name, url }));
  return {
    code: categoryCode,
    url: isDiscountCategory ? discountCategory?.url : categoryData.url,
    subCategories: subCategories,
    breadcrumbs: categoryData.breadcrumbs,
    discountsSubcategory: discountCategory !== null,
    header: categoryData.header,
  };
};

export const fetchCategoryDataMemoized = memoize(
  fetchCategoryData,
  new Keyv({ namespace: "category-data" }),
  {
    ttl: CACHE_TTL_15_MIN,
    staleTtl: STALE_TTL_3_MIN,
  }
);

export const getSuperCategoryCodes = (category: CategoryData): string[] => {
  const breadcrumbs = category.breadcrumbs ?? [];
  return breadcrumbs
    .slice(1, Math.max(0, breadcrumbs.length - 2))
    .map((breadcrumbCategory: CategoryData) => breadcrumbCategory.code ?? "")
    .filter(Boolean);
};
